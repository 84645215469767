<template>
  <b-card>
    <h6>
      {{ `${$t('fields.result')} (${filteredList.length === selectedLimit ? filteredList.length + "+" : filteredList.length})` }}
    </h6>
    <b-row>
      <b-col md="3" offset-md="3">
        <MasterSelectInput
          v-show="isOwner"
          :value="selectedMaster"
          hide-label
          @update="onMasterIdChange"
        />
      </b-col>
      <b-col md="3">
        <AgentsSelectInput
          v-show="isOwner || isMaster"
          :value="selectedAgent"
          :master-id="selectedMaster"
          hide-label
          @update="onAgentIdChange"
        />
      </b-col>
      <b-col md="3">
        <UserAgentSelectInput
          :value="selectedUserAgent"
          :agent-id="selectedAgent"
          hide-label
          @update="onUserAgentIdChange"
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col md="6">
        <multiselect
          v-model="selectedBanks"
          placeholder="เลือกบัญชี"
          :multiple="true"
          :options="agentBanks"
          label="text"
          track-by="value"
        >
          <template slot="option" slot-scope="props">
            <div class="d-flex align-items-center" style="height: 25px">
              <BankIcon :bank-code="props.option.bankCode" />
              <span class="mx-2">{{ props.option.text }}</span>
            </div>
          </template>
        </multiselect>
      </b-col>
      <b-col cols="4">
        <b-select v-model="selectedType" :options="typeOptions[type]">
        </b-select>
      </b-col>
      <b-col v-if="$allowPermission('agent:export.deposit') && type === 'deposit'" cols="2">
        <b-button class="btn btn-info btn-block" :disabled="exporting" @click="exportDepositTransactions($event)">
          <template v-if="exporting">
            <b-spinner small />
          </template>
          <template v-else>
            <i class="uil uil-download-alt"></i> ดาวน์โหลด
          </template>
        </b-button>
      </b-col>
      <b-col v-if="$allowPermission('agent:export.withdraw') && type === 'withdraw'" cols="2">
        <b-button class="btn btn-info btn-block" :disabled="exporting" @click="exportWithdrawTransactions($event)">
          <template v-if="exporting">
            <b-spinner small />
          </template>
          <template v-else>
            <i class="uil uil-download-alt"></i> ดาวน์โหลด
          </template>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="search"
            type="search"
            :placeholder="`${$t('fields.search')}... (เบอร์/ เลขที่บช./ ชื่อ)`"
            @keydown.enter="fetchData"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col md="7">
        <b-input-group>
          <template #prepend>
            <b-select v-model="dateType">
              <b-select-option value="createdAt">เวลาแจ้ง</b-select-option>
              <b-select-option value="transactionDateTime">เวลาโอน</b-select-option>
            </b-select>
          </template>
          <b-form-datepicker
            v-model="startDate"
            reset-button
          ></b-form-datepicker>
          <b-input-group-append is-text>ถึง</b-input-group-append>
          <b-form-datepicker v-model="endDate" reset-button></b-form-datepicker>
        </b-input-group>
      </b-col>
      <b-col md="2" class="text-right">
        <b-button block @click="fetchData">
          <i class="uil uil-search"></i> ค้นหา
        </b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      class="mt-3"
      :items="filteredList"
      :fields="type === 'deposit' ? depositFields : withdrawFields"
      :busy="isFetching"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(transactionDateTime)="data">
        {{ data.value | datetime }}
      </template>
      <template v-if="type === 'withdraw'" #cell(userAgentUsername)="data">
        {{ data.value }}
      </template>
      <template #cell(updatedAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(fromAccountNumber)="data">
        <component
          :is="
            $allowPermission('agent:manage.user') && data.item.userId
              ? 'a'
              : 'span'
          "
          :href="`/players/detail/${data.item.userId}`"
          target="_blank"
        >
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="data.item.fromBankCode" />
            </template>
            {{ data.item.fromAccountNumber }}<br />
            {{ data.item.fromAccountName }}
          </b-media>
        </component>
      </template>
      <template #cell(toAccountNumber)="data">
        <component
          :is="
            $allowPermission('agent:manage.user') && data.item.userId
              ? 'a'
              : 'span'
          "
          :href="`/players/detail/${data.item.userId}`"
          target="_blank"
        >
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="data.item.toBankCode" />
            </template>
            {{ data.item.toAccountNumber }}<br />
            {{ data.item.toAccountName }}
          </b-media>
        </component>
      </template>

      <template #cell(toBankCode)="data">
        <component
          :is="
            $allowPermission('agent:manage.user') && data.item.userId
              ? 'a'
              : 'span'
          "
          :href="`/players/detail/${data.item.userId}`"
          target="_blank"
        >
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="data.value" />
            </template>
            {{ data.item.toBankAccountNumber }}<br />
            {{ data.item.userFirstname }} {{ data.item.userLastname }}
          </b-media>
        </component>
      </template>
      <template #cell(amount)="data">
        <span :class="type === 'deposit' ? 'text-success' : 'text-warning'">
          {{ data.value | currency }}
        </span>
      </template>
      <template #cell(afterBalance)="data">
        <b-badge class="badge-soft-danger">
          {{ data.item.beforeBalance | currency }} </b-badge
        ><br />
        <b-badge class="badge-soft-success">
          {{ data.value | currency }}
        </b-badge>
      </template>
      <template #cell(remain)="data">
        {{ displayRemainAmount(data.item) }}
      </template>
      <template #cell(status)="data">
        <template v-if="type === 'deposit'">
          <b-badge v-if="+data.value === 0" variant="success">
            {{ $t('utils.status.success_credit') }}
          </b-badge>
          <b-badge v-else-if="+data.value === 1" variant="dark">
            ฝากมือ
          </b-badge>
          <b-badge v-else-if="+data.value === 2" variant="dark">
            ไม่เจอยูส
          </b-badge>
          <b-badge v-else-if="+data.value === 4" variant="warning">
            ดึงเครดิตคืน
          </b-badge>
          <b-badge v-else variant="danger">
            {{ $t('utils.status.unsuccessful') }}
          </b-badge>
        </template>
        <template v-else>
          <b-badge v-if="+data.value === 0" variant="light">
            รอดำเนินการ
          </b-badge>
          <b-badge v-else-if="+data.value === 1" variant="warning">
            ซ่อน
          </b-badge>
          <b-badge v-else-if="+data.value === 2" variant="danger">
            ปฏิเสธ
          </b-badge>
          <b-badge v-else-if="+data.value === 3" variant="success">
            ถอนออโต้
          </b-badge>
          <b-badge v-else-if="+data.value === 4" variant="success">
            ถอนมือ
          </b-badge>
        </template>
      </template>
      <template #cell(transactionRemark)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(transactionChannelCode)="data">
        <b-badge>
          <template v-if="data.item.transactionChannelDescription">
            {{ data.item.transactionChannelDescription || '-' }}
            ({{ data.value || '-' }})
          </template>
          <template v-else>
            {{ data.value || '-' }}
          </template>
        </b-badge>
      </template>
      <template #cell(imageUrl)="data">
        <b-button
          variant="primary"
          size="sm"
          :disabled="!data.value"
          @click="showImage(data.value)"
        >
          <i class="uil uil-eye"></i>
        </b-button>
      </template>
      <template #cell(operation)="data">
        <b-button
          v-if="[0, 1].includes(+data.item.status)"
          variant="danger"
          @click="openToggleDepositModal(data.item)"
        >
          <i class="uil uil-repeat"></i>
          ดึงคืน
        </b-button>
        <b-button
          v-if="[3, 4].includes(data.item.status)"
          variant="primary"
          @click="openToggleDepositModal(data.item)"
        >
          <i class="uil uil-redo"></i>
          คืนเครดิต
        </b-button>
      </template>
      <template #cell(remark)="data">
        {{ data.value }}
        <b-button
            v-if="data.item.imageUrl"
            variant="primary"
            size="sm"
            @click="showImage(data.item.imageUrl)"
        >
          <i class="uil uil-eye"></i>
        </b-button>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
    <!-- pagination -->
    <b-row align-v="center" class="mb-3">
      <b-col cols="4">
        <LimitSelect v-model="selectedLimit" />
      </b-col>
      <b-col>
        <SimplePagination
          :per-page="limit"
          :has-more-page="pagination.itemCount >= selectedLimit"
          @update="(val) => (currentPage = val)"
        />
      </b-col>
    </b-row>
    <toggle-deposit-modal :txn="selectedTxn" :search="search" />
  </b-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import dayjs from 'dayjs';

export default {
  name: 'DoneTransactions',
  components: {
    SimplePagination: () => import('@components/commons/simple-pagination'),
    ToggleDepositModal: () =>
      import('@components/transactions/toggle-deposit-modal'),
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
    UserAgentSelectInput: () =>
      import('@components/user-agent/user-agent-select-input'),
  },
  props: {
    type: {
      type: String,
      default: 'deposit',
    },
  },
  data() {
    return {
      currentPage: 1,
      search: '',
      filterType: '',
      onSearchTimeout: null,
      selectedTxn: {},
      depositFields: [
        '#',
        {
          key: 'updatedAt',
          label: 'เวลาปรับยอด',
        },
        {
          key: 'transactionDateTime',
          label: 'เวลาฝาก',
        },
        {
          key: 'fromAccountNumber',
          label: 'บัญชีโอน',
        },
        {
          key: 'toAccountNumber',
          label: 'บัญชีรับ',
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
        },
        {
          key: 'afterBalance',
          label: 'คงเหลือ (ก่อน-หลัง)',
          class: 'text-right',
        },
        {
          key: 'transactionChannelCode',
          label: this.$t('fields.channel'),
          class: 'text-center',
        },
        {
          key: 'status',
          label: this.$t('fields.status'),
          class: 'text-center',
        },
        {
          key: 'transactionRemark',
          label: this.$t('fields.remark'),
        },
        {
          key: 'imageUrl',
          label: 'สลิปโอนเงิน',
          class: 'text-center',
        },
        {
          key: 'operation',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
        },
      ],
      withdrawFields: [
        '#',
        {
          key: 'toBankCode',
          label: 'บัญชี',
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
        },
        {
          key: 'userAgentUsername',
          label: 'กระทำโดย',
        },
        {
          key: 'afterBalance',
          label: 'คงเหลือ (ก่อน-หลัง)',
          class: 'text-right',
        },
        {
          key: 'createdAt',
          label: 'เวลาแจ้ง',
        },
        {
          key: 'transactionDateTime',
          label: 'เวลาโอน',
        },
        {
          key: 'status',
          label: 'สถานะ',
          class: 'text-center',
        },
        {
          key: 'remark',
          label: this.$t('fields.remark'),
        },
      ],
      selectedMaster: '',
      selectedAgent: '',
      selectedUserAgent: '',
      selectedLimit: 20,
      selectedBanks: [],
      selectedType: null,
      startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      dateType: 'createdAt',
      typeOptions: {
        withdraw: [
          { value: null, text: 'ทุกสถานะ' },
          { value: 3, text: 'ถอนออโต้' },
          { value: 4, text: 'ถอนมือ' },
          { value: 2, text: 'ปฏิเสธ' },
        ],
        deposit: [
          { value: null, text: 'ทุกสถานะ' },
          { value: 0, text: 'ฝากออโต้' },
          { value: 1, text: 'ฝากมือ' },
          { value: 3, text: 'ปฏิเสธ' },
        ],
      },
      exporting: false,
      agentList: [],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) =>
        state.deposit.isFetchingDepositDone ||
        state.withdraw.isFetchingWithdrawDone,
    }),
    ...mapGetters([
      'isAgent',
      'isOwner',
      'isMaster',
      'depositDone',
      'withdrawDone',
      'depositBankAccounts',
      'withdrawBankAccounts',
      'userInfo'
    ]),
    filteredList() {
      return this.type === 'deposit'
        ? this.depositDone.items || []
        : this.withdrawDone.items
    },
    pagination() {
      return this.type === 'deposit'
        ? this.depositDone.meta
        : this.withdrawDone.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    agentBanks() {
      const bankList =
        this.type === 'deposit'
          ? this.depositBankAccounts
          : this.withdrawBankAccounts
      if (bankList.length > 0) {
        if (this.selectedMaster && this.selectedAgent || this.selectedAgent) {
          return bankList.filter((bank) => {
            return bank.agentId === this.selectedAgent
          }).map((bank) => ({
            value: bank.id,
            text: `${bank.bankAccountNumber} (${bank.bankAccountName})`,
            bankCode: bank.bankCode,            
          }))
        } else if (this.selectedMaster && this.agentList) {
          const tmp = []
          this.agentList.map((agent) => {

            bankList.filter((bank) => {
              return bank.agentId === agent.id
            }).map((bank) => {
              tmp.push({
                id: bank.id,
                bankAccountNumber: bank.bankAccountNumber,
                bankAccountName: bank.bankAccountName,
                bankCode: bank.bankCode
              })
            })
          })
          return tmp.map((t) => ({
            value: t.id,
            text: `${t.bankAccountNumber} (${t.bankAccountName})`,
            bankCode: t.bankCode,            
          }))
          
        } else {
          return bankList.map((bank) => ({
            value: bank.id,
            text: `${bank.bankAccountNumber} (${bank.bankAccountName})`,
            bankCode: bank.bankCode,
          }))
        }
      }
      return []
    },
    selectedAgentBankAccounts() {
      return this.selectedBanks?.map((bank) => bank.value).join(',') || ''
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
  },
  created() {
    if (this.type === 'deposit') {
      this.fetchDepositBankAccounts()
    } else {
      this.fetchWithdrawBankAccounts()
    }
    this.fetchDataAssistants()
  },
  methods: {
    ...mapActions([
      'fetchDepositDone',
      'fetchWithdrawDone',
      'fetchDepositBankAccounts',
      'fetchWithdrawBankAccounts',
      'fetchAssistantsForInput',
      'exportDepositTransaction',
      'exportWithdrawTransaction'
    ]),
    async exportDepositTransactions(e) {
      if(!this.filteredList.length) {
        await this.$store.dispatch('notifyError', 'ไม่มีรายการฝาก')
        e.preventDefault()
        return;
      }
      this.exporting = true
      await this.exportDepositTransaction({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        masterId: this.selectedMaster,
        agentId: this.selectedAgent,
        userAgentId: this.selectedUserAgent,
        agentBankAccountIds: this.selectedAgentBankAccounts,
        type: this.selectedType,
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.exporting = false
    },
    async exportWithdrawTransactions(e) {
      if(!this.filteredList.length) {
        await this.$store.dispatch('notifyError', 'ไม่มีรายการฝาก')
        e.preventDefault()
        return;
      }
      this.exporting = true
      await this.exportWithdrawTransaction({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        masterId: this.selectedMaster,
        agentId: this.selectedAgent,
        userAgentId: this.selectedUserAgent,
        agentBankAccountIds: this.selectedAgentBankAccounts,
        type: this.selectedType,
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.exporting = false
    },
    fetchData() {
      if (!this.isAgent && !this.selectedAgent.length) return
      if (this.type === 'deposit') {
        this.fetchDepositDone({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          masterId: this.selectedMaster,
          agentId: this.selectedAgent,
          userAgentId: this.selectedUserAgent,
          agentBankAccountIds: this.selectedAgentBankAccounts,
          type: this.selectedType,
          startDate: this.startDate,
          endDate: this.endDate,
          dateType: this.dateType,
        })
      } else {
        this.fetchWithdrawDone({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          masterId: this.selectedMaster,
          agentId: this.selectedAgent,
          userAgentId: this.selectedUserAgent,
          agentBankAccountIds: this.selectedAgentBankAccounts,
          type: this.selectedType,
          startDate: this.startDate,
          endDate: this.endDate,
          dateType: this.dateType,
        })
      }
    },
    fetchDataAssistants() {
      this.fetchAssistantsForInput()
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.$emit('on-agent-change', agentId)
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.$emit('on-master-change', masterId)
      this.fetchData()
      const { masters } = this.userInfo
      if (this.selectedMaster && masters) {
        const foundMyAgent = this.userInfo.masters.find(
          (master) => master.id === this.selectedMaster
        )
        if(foundMyAgent) {
          this.agentList = JSON.parse(JSON.stringify(foundMyAgent.children))
          this.agentList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
      }
    },
    onUserAgentIdChange(userAgentId) {
      this.selectedUserAgent = userAgentId
      this.$emit('on-user-agent-change', userAgentId)
      this.fetchData()
    },
    displayRemainAmount(txn) {
      const remain = Number(txn.beforeBalance) - Number(txn.afterBalance)
      return remain.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    openToggleDepositModal(txn) {
      this.selectedTxn = txn
      this.$bvModal.show('toggle-deposit-modal')
    },
    showImage(imageUrl) {
      const h = this.$createElement
      const messageVNode = h('img', {
        class: ['w-100'],
        attrs: { src: imageUrl },
      })
      this.$bvModal.msgBoxOk([messageVNode], {
        centered: true,
        okTitle: 'ปิด',
      })
    },
  },
}
</script>
